import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import App from './App';
import { store } from './store/store';
import { TonConnectUIProvider } from '@tonconnect/ui-react';

const tonConnectManifestUrl = 'https://luckystar.bot/tonconnect-manifest.json';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <TonConnectUIProvider manifestUrl={tonConnectManifestUrl}>
        <React.StrictMode>
            <Provider store={store}>
                <App />
            </Provider>
        </React.StrictMode>
    </TonConnectUIProvider>
);
