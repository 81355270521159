import React from "react"
import starImg from '../../../assets/img/icons/game/bets/star_item_orange.svg'
import starImgGrey from '../../../assets/img/icons/game/bets/star_item_pick.svg'

const betStars = [
    {id: 1},
    {id: 2},
    {id: 3},
    {id: 4},
    {id: 5},
]

const HistoryGameResult= ({
    gameData
}) => {
    const {
        date,
        betAmount,
        bets,
        gameResult,
        winNumber,
        fee,
        airdrop = 0
    } = gameData
    return (
        <div className="history-item history-item_game_result">
            <div className={"history-item__img" + (gameResult > 0 ? ' _win' : '') + (gameResult > 99 ? " _long" : "")}>
                <img src={gameResult > 0 ? starImg : starImgGrey} alt="" />
                <span>{gameResult}</span>
            </div>
            <div className="history-item__info">
                <div className="history-item__left">
                    <div className="history-item__topper">
                        <div className="history-item__stars">
                            {betStars.map(star => (
                                <div key={star.id} className={"history-game-result-star" + (bets.includes(star.id) ? ' _active' : '') + (star.id === winNumber ? ' _win' : '')}></div>
                            ))}
                        </div>
                    </div>
                    <div className="history-item__changes">
                        <div className="history-item__spend">
                            <div className="history-game-result-star _active"></div>
                            {betAmount * bets.length}
                        </div>
                        <div className="history-item__profit">
                            <div className={"history-game-result-star _active" + (gameResult > 0 ? " _win" : '')}></div>
                            {gameResult}
                        </div>
                        {gameResult > 0 &&
                            <div className="history-item__benefits">
                                <div className="history-game-result-star _active"></div>
                                <span>{fee}</span>
                                Service fee
                            </div>
                        }
                    </div>
                    <div className="history-item__date">
                        <div>{date}</div>
                    </div>
                </div>
                <div className="history-item__right">
                    {gameResult > 0
                        ?
                        <div className={gameResult - betAmount * bets.length - fee > 0 ? "history-item__win" : 'history-item__lose'}><span>+</span>{gameResult - betAmount * bets.length - fee}</div>
                        :
                        <div className="history-item__lose">-{betAmount * bets.length}</div>
                    }
                    <div className="history-item__airdrop">+{airdrop.toLocaleString()}</div>
                </div>
            </div>
        </div>
    )
};

export default HistoryGameResult
