import React, { useEffect, useState } from "react"
import BoxWrapper from "../Wrappers/BoxWrapper";
import HistoryItem from "./HistoryItems/HistoryItem";
import { useGetAllHistoryGamesQuery } from "../../store/services/starsGame";
import { useTelegram } from "../../hooks/useTelegram";
import { useScrollPosition } from "../../hooks/useScrollPosition";

const historyFilters = [
    {id: 1, name: 'All', filterVal: 'all'},
    {id: 2, name: 'Playlist', filterVal: 'play'},
    {id: 3, name: 'Add stars', filterVal: 'stars'},
    {id: 4, name: 'Add tries', filterVal: 'tries'},
    {id: 5, name: 'Withdraw', filterVal: 'withdraw'},
]

const HistoryList= ({

}) => {
    const [listItems, setListItems] = useState([])
    const [filterActive, setFilterActive] = useState('')
    const [isGreyNav, setIsGreyNav] = useState(false)
    const {user: tgUser} = useTelegram()
    const {data: historyGamesData, isLoading: isHistoryGamesLoading, isError: isHistoryGamesError} = useGetAllHistoryGamesQuery(tgUser)

    useEffect(() => {
        setFilterActive(historyFilters[0].filterVal)
    }, [])

    useScrollPosition(({prevPos, currPos}) => {
        const isShow = -currPos.y > 370
        if (isShow) {
            setIsGreyNav(true)
        } else {
            setIsGreyNav(false)
        }
    }, [isGreyNav])

    useEffect(() => {
        if (!isHistoryGamesLoading && historyGamesData?.length) {
            switch (filterActive) {
                case "play":
                    setListItems([...historyGamesData.filter(item => item.type === 'game')])
                    break;
            
                case "stars":
                    setListItems([...historyGamesData.filter(item => item.type === 'A' || item.type === 'P' || item.type === 'S')])
                    break;
            
                case "tries":
                    setListItems([...historyGamesData.filter(item => item.type === 'tries')])
                    break;
            
                case "withdraw":
                    setListItems([...historyGamesData.filter(item => item.type === 'withdraw')])
                    break;
            
                default:
                    setListItems([...historyGamesData])
                    break;
            }
        }
    }, [historyGamesData, isHistoryGamesLoading, filterActive])

    // if (isHistoryGamesLoading) {
    //     return (<BoxWrapper className={'history-list'}><div className="loader"></div></BoxWrapper>)
    // }
    

    if (!historyGamesData?.length) {
        return (<BoxWrapper className={'history-list'}><p className="history-list__empty">History is empty</p></BoxWrapper>)
    }

    const tonRate = 0.00126917
    return (
        <BoxWrapper className={'history-list _only'}>
            <div className={"history-list__filters" + (isGreyNav ? ' _grey' : "")}>
                {historyFilters.map(filter => (
                    <div 
                        key={filter.id}
                        className={"history-list__filteritem" + (filter.filterVal === filterActive ? " _active" : "")}
                        onClick={() => setFilterActive(filter.filterVal)}
                    >
                        {filter.name}
                    </div>
                ))}
            </div>
            <div className="history-list__inner">
                {!listItems.length && <p className="history-list__empty">History is empty</p>}
                {listItems.map(historyItem => (
                    <HistoryItem 
                        historyItem={historyItem}
                        tonRate={tonRate}
                        key={`${historyItem.type}_${historyItem.id}`}
                    />
                ))}
            </div>
        </BoxWrapper>
    )
};

export default HistoryList
