import React from 'react';
import { NavLink } from 'react-router-dom';
import { useTelegram } from '../../hooks/useTelegram';

const BoxWrapper = ({
    children,
    linkPath = null,
    className = null,
    tgLink = null
}) => {
    const { tg } = useTelegram()
    
    const handleTgLink = () => {
        if (tgLink !== null) {
            tg.openLink(tgLink)
        }
    }
    return (
        linkPath !== null
            ?
            <NavLink className={'s5-box-wrapper ' + (className ? className : '')} to={linkPath}>
                {children}
            </NavLink>
            :
            <div className={'s5-box-wrapper ' + (className ? className : '')} onClick={handleTgLink}>
                {children}
            </div>
        
    );
};

export default BoxWrapper;