import { TonConnectButton, useIsConnectionRestored, useTonAddress, useTonConnectUI } from "@tonconnect/ui-react";
import React, { useEffect } from "react"
import { useTelegram } from "../../../hooks/useTelegram";
import RequestButton from "../RequestButton/RequestButton";

const TonCustomButton = ({
    
}) => {
    const userFriendlyAddress = useTonAddress();
    const connectionRestored = useIsConnectionRestored();
    const [tonConnectUI, setOptions] = useTonConnectUI();
    const rawAddress = useTonAddress(false);
    const { sendAlert } = useTelegram()

    useEffect(() => {
        // if (userFriendlyAddress) {
        //     sendAlert(`Your TON adress is: ${userFriendlyAddress}`)
        // } else {
        //     sendAlert(`TON adress is not connected`)
        // }
    }, [userFriendlyAddress])

    const withdrawFromAdress = () => {
        sendAlert(`Request to withdraw to TON adress: ${userFriendlyAddress}`)
    }

    if (!connectionRestored) {
        return (
            <RequestButton isloading={true}></RequestButton>
        )
    }

    return (
        // <TonConnectButton className="request-btn" style={{width: '100%'}}/>\
        userFriendlyAddress
            ?
            <RequestButton
                className={"title-history__ton_btn"}
                onClick={() => withdrawFromAdress()}
            >WITHDRAW</RequestButton>
            :
            <RequestButton
                className={"title-history__ton_btn"}
                onClick={() => tonConnectUI.openModal()}
            >CONNECT TON WALLET</RequestButton>
        
    )
};

export default TonCustomButton;
