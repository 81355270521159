import React, { useEffect } from "react";
import PendingWithdraws from "../../components/AdminPanel/PendingWithdraws";
import PremiumPartners from "../../components/AdminPanel/PremiumPartners";
import { useScroll } from "../../hooks/useScroll";

const Manage = ({
    
}) => {
    const { scrollTop } = useScroll()

    useEffect(() => {
        scrollTop()
    }, [])

    return (
        <div className="admin-manage-withdraws affilate-page _premium">
            <p>Withdraw requests:</p>
            <PendingWithdraws />
            <p>Premium partners:</p>
            <PremiumPartners />
        </div>
    )
};

export default Manage;
