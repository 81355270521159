import React, { useEffect, useState } from "react"
import winNumber from '../../assets/img/icons/win_star.svg'
import { useApp } from "../../hooks/useApp";
import { useStarGame } from "../../hooks/useStarGame";
import { useScroll } from "../../hooks/useScroll";
import { useLocation } from "react-router-dom";
import RequestButton from "../../components/UI/RequestButton/RequestButton";
import { useTelegram } from "../../hooks/useTelegram";
import { useGenerateHashFromStringMutation } from "../../store/services/starsGame";
import BoxWrapper from "../../components/Wrappers/BoxWrapper";
import starImg from '../../assets/img/icons/game/bets/star_item_orange.png'
import copyPasteImg from '../../assets/img/icons/clarity_paste-line.svg'
import Star from "../../components/Star/Star";
import StarTitle from "../../components/Star/StarTitle";


const starTable = [
    {number: 1, winNumber: [1]},
    {number: 2, winNumber: [2]},
    {number: 3, winNumber: [3]},
    {number: 4, winNumber: [4]},
    {number: 5, winNumber: [5, 0]},
]

const CheckWinNumber= ({
    
}) => {
    const {
        isLoaded
    } = useApp()

    const {
        isGameFinished,
        resultNumber,
        hash_1,
        hash_2
    } = useStarGame()

    const { sendAlert, hideTgButton } = useTelegram()
    const { scrollTop } = useScroll()
    const location = useLocation()
    const [isHashCopied, setIsHashCopied] = useState(false)
    const [isHash1Locked, setIsHash1Locked] = useState(false)
    const [testHash1, setTestHash1] = useState('')
    const [hash2Generated, setHash2Generated] = useState('')
    const [hexString, setHexString] = useState('')
    const [decimalNum, setDecimalNum] = useState('')
    const [pickedBit, setPickedBit] = useState({bitName: '', val: ''})
    const [generateHash, {data: generatedHash, isloading: isGeneratedHashLoading}] = useGenerateHashFromStringMutation()


    useEffect(() => {
        scrollTop()
    }, [location.pathname])

    const handleHashChange = (e) => {
        setTestHash1(e.target.value.toUpperCase())
    }

    const copyHash = () => {
        setTestHash1('')
        clearHash()
        navigator.clipboard.writeText(hash_1).then(() => {
            // sendAlert('HASH copied to clipboard');
            setIsHashCopied(true)
        /* Resolved - text copied to clipboard successfully */
        },() => {
            sendAlert('Failed to copy');
        /* Rejected - text failed to copy to the clipboard */
        });
    }

    const generateHashHandler = async () => {
        await generateHash(testHash1)
        setIsHash1Locked(true)
    }
    
    const pasteHash = () => {
        setTestHash1(hash_1)
    }
    const clearHash = () => {
        setTestHash1("")
        setDecimalNum('')
        setHexString('')
        setIsHashCopied(false)
        setIsHash1Locked(false)
        setHash2Generated('')
    }

    const handleHexStringChange = (e) => {
        setHexString(e.target.value.toUpperCase())
        setDecimalNum('')
    }
    const handleDecimalNumChange = (e) => {
        setDecimalNum(e.target.value)
        setHexString('')
    }

    const convertHexAndDecimal = () => {
        if (hexString !== '') {
            setDecimalNum(parseInt(hexString, 16).toString())
        }
        if (decimalNum !== '') {
            const newHex = parseInt(decimalNum).toString(16).toUpperCase()
            if (newHex.length === 2) {
                setHexString(newHex)
            } else {
                setHexString(`0${newHex}`)
            }
        }
    }

    const pickBit = (bitName) => {
        setHexString(bitName)
        setDecimalNum(parseInt(bitName, 16).toString())
    }

    useEffect(() => {
        setPickedBit({
            bitName: hexString,
            val: decimalNum.toString()
        })
    }, [hexString, decimalNum])

    useEffect(() => {
        if (generatedHash && !isGeneratedHashLoading) {
            setHash2Generated(generatedHash.hash)
        }
    }, [generatedHash, isGeneratedHashLoading])

    useEffect(() => {
        hideTgButton()
    }, [])

    if (!isGameFinished) {
        return
    }

    return (
        <>
            <Star />    
            <StarTitle />
            <div className="check-number-page">
                <div className="intro-star-topper__subtitle">Win Number</div>
                <div className="title-affilate__text">
                    <p>Check the Win Number following the instructions below</p>
                </div>

                <div className="hashes-check-number">
                    <div className="hashes-check-number__item _revealed">
                        {hash_1 === testHash1 && <div className="hashes-check-number__scs">
                            <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M0 7.5C0 3.35795 3.35795 0 7.5 0C11.642 0 15 3.35795 15 7.5C15 11.642 11.642 15 7.5 15C3.35795 15 0 11.642 0 7.5Z" fill="#31B545"/>
                            <path d="M6.30213 10.4414L3.40918 7.54774L4.37327 6.58365L6.30213 8.51183L10.1585 4.65479L11.1233 5.61956L6.30213 10.4414Z" fill="white"/>
                            </svg>
                        </div>}
                        <span>Revealed hash</span>
                        <strong>{hash_1}</strong>
                        <p>This value contains a win number from 1 to 5. For check it copy this value and generate hash below.</p>
                    </div>
                        <RequestButton 
                            disabled={isHashCopied}
                            className="hashes-check-number__btn" 
                            onClick={copyHash}>
                                {isHashCopied ? "COPIED" : "COPY VALUE"}
                        </RequestButton>
                </div>
                <div className="hashes-check-number">
                    <div className="hashes-check-number__item _win">
                        {hash2Generated === hash_2 && <div className="hashes-check-number__scs">
                            <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M0 7.5C0 3.35795 3.35795 0 7.5 0C11.642 0 15 3.35795 15 7.5C15 11.642 11.642 15 7.5 15C3.35795 15 0 11.642 0 7.5Z" fill="#C4B373"/>
                            <path d="M6.30213 10.4414L3.40918 7.54774L4.37327 6.58365L6.30213 8.51183L10.1585 4.65479L11.1233 5.61956L6.30213 10.4414Z" fill="white"/>
                            </svg>
                        </div>}
                        <span>Win number hash</span>
                        <strong>{hash_2}</strong>
                        <p>This hash is the encrypted value above, which was displayed before you’ve made your choice.</p>
                    </div>
                </div>

                <div className="check-number-page__sha256">
                    SHA256 HASH GENERATOR
                </div>
                <div className="hashes-check-number">
                    <div className="conversion-hash__title">
                        Compare the values
                    </div>
                    <p>For be sure that all values with win number are correct.</p>
                    <div className={"hashes-check-number__hash _1" + (hash_1 === testHash1 ? " _true_1" : "")}>
                        {hash_1 === testHash1 && <div className="hashes-check-number__scs">
                            <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M0 7.5C0 3.35795 3.35795 0 7.5 0C11.642 0 15 3.35795 15 7.5C15 11.642 11.642 15 7.5 15C3.35795 15 0 11.642 0 7.5Z" fill="#31B545"/>
                            <path d="M6.30213 10.4414L3.40918 7.54774L4.37327 6.58365L6.30213 8.51183L10.1585 4.65479L11.1233 5.61956L6.30213 10.4414Z" fill="white"/>
                            </svg>
                        </div> }
                        {testHash1 &&
                            <div className="hashes-check-number__clear" onClick={clearHash}>
                                <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="7.5" cy="7.5" r="7.5" fill="#848484"/>
                                <rect x="4.50098" y="5.5" width="1.4142" height="7.071" transform="rotate(-45 4.50098 5.5)" fill="white"/>
                                <rect x="5.5" y="10.5" width="1.4142" height="7.071" transform="rotate(-135 5.5 10.5)" fill="white"/>
                                </svg>
                            </div>
                        }
                        <div className="hashes-check-number__label">Value</div>
                        <textarea onChange={handleHashChange} value={testHash1} style={isHash1Locked ? {pointerEvents:'none'} : {} } />
                        {isHashCopied && !testHash1 &&
                            <div className="paste-btns">
                                <div className="hashes-check-number__btn" onClick={pasteHash}>
                                    <img src={copyPasteImg} alt="" />
                                    Paste value
                                </div>
                                <div className="hashes-check-number__btn" onClick={clearHash}>
                                    <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect width="35" height="35" rx="12" fill="#EFEEF4"/>
                                    <path d="M13.0222 23.908L12.0908 22.9766L22.9779 12.0896L23.9092 13.021L13.0222 23.908Z" fill="black"/>
                                    <rect width="1.31717" height="15.3966" transform="matrix(0.707107 -0.707107 -0.707107 -0.707107 22.9785 23.908)" fill="black"/>
                                    </svg>
                                </div>
                            </div>
                        }
                    </div>
                    <div className={"hashes-check-number__hash _2" + (generatedHash?.hash && hash_1 === testHash1 && hash2Generated ? " _true_2" : "")}>
                        {generatedHash?.hash && hash_1 === testHash1 && hash2Generated && <div className="hashes-check-number__scs">
                            <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M0 7.5C0 3.35795 3.35795 0 7.5 0C11.642 0 15 3.35795 15 7.5C15 11.642 11.642 15 7.5 15C3.35795 15 0 11.642 0 7.5Z" fill="#C4B373"/>
                            <path d="M6.30213 10.4414L3.40918 7.54774L4.37327 6.58365L6.30213 8.51183L10.1585 4.65479L11.1233 5.61956L6.30213 10.4414Z" fill="white"/>
                            </svg>
                        </div>}
                        <div className="hashes-check-number__label">Hash</div>
                        <p>{generatedHash?.hash && hash2Generated ? generatedHash?.hash : ''}</p>
                    </div>
                    <RequestButton
                        className={"hashes-check-number__btn"}
                        isloading={isGeneratedHashLoading}
                        disabled={testHash1.length === 0 || (hash2Generated && generatedHash?.hash)}
                        onClick={generateHashHandler}
                    >
                        GENERATE HASH
                    </RequestButton>
                </div>

                {hash2Generated.length > 0 && generatedHash?.hash && testHash1 && hash2Generated === hash_2 &&
                <>
                    <div className="check-number-page__sha256">
                        HEX TO DECIMAL CONVERTER
                    </div>
                    <div className="conversion-hash">
                        <div className="conversion-hash__title">
                        Hash conversion to decimal numbers
                        </div>
                        <p>For getting a win number we have to convert all bytes of revealed hash to decimal numbers.</p>
                        <div className="conversion-hash__bitsholder">
                            <div className="conversion-hash__prefix">
                                <span>H</span>
                                <span>D</span>
                            </div>
                            <div className="conversion-hash__bits">
                                {generatedHash?.bits.map((bitItem, index) => (
                                    <div 
                                        key={`bit_${bitItem.bitName}_${index}`} 
                                        className={"conversion-hash__bit" + (bitItem.bitName === pickedBit.bitName && bitItem.val.toString() === pickedBit.val.toString() ? ' _active' : "")}
                                        onClick={() => pickBit(bitItem.bitName)}
                                    >
                                        <span>{bitItem.bitName}</span>
                                        <span>{bitItem.val}</span>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="conversion-hash__btns">
                            <div>
                                <span>Hex number</span>
                                <input 
                                    type="text" 
                                    value={hexString}
                                    className={generatedHash?.bits.filter(item => item.bitName == hexString).length === 0 && hexString.length > 0 ? '_error' : ""}
                                    onChange={handleHexStringChange}
                                />
                            </div>
                            <div>
                                <span>Decimal number</span>
                                <input 
                                    type="text" 
                                    value={decimalNum}
                                    className={generatedHash?.bits.filter(item => item.val == decimalNum).length === 0 && decimalNum.length > 0 ? '_error' : ""}
                                    onChange={handleDecimalNumChange}
                                />
                            </div>
                            <div
                            className={
                                (generatedHash?.bits.filter(item => item.val == decimalNum).length === 0 && decimalNum.length > 0) ||
                                (generatedHash?.bits.filter(item => item.bitName == hexString).length === 0 && hexString.length > 0) ||
                                (hexString.length === 0 && decimalNum.length === 0)                          
                                ? "_disabled" : ""
                            } 
                            onClick={convertHexAndDecimal}>CONVERT</div>
                        </div>
                    </div>
                </>
                }

                {generatedHash && hash2Generated && testHash1 && hash2Generated === hash_2 &&
                <>
                    <div className="check-number-page__sha256">
                        GETTING WIN NUMBER
                    </div>
                    <BoxWrapper>

                        <div className="win-number-result">
                            <div className="win-number-result__title">Win number formula</div>
                            <div className="win-number-result__row">
                                <div className="win-number-result__item">
                                    <span>
                                        1
                                        <div className="dot_post"></div>
                                    </span>
                                    <div>
                                        <p>
                                        Summering all gotten decimal numbers = <span>{generatedHash.hash_sub}</span>
                                        </p>
                                    </div>
                                </div>
                                <div className="win-number-result__item">
                                    <span>
                                        <div className="dot_pre"></div>
                                        2
                                        <div className="dot_post"></div>
                                    </span>
                                    <div>
                                        <p>
                                        Dividing this sum on 5 = <span>{Math.floor(generatedHash.hash_sub / 5)} + {generatedHash.hash_sub % 5}</span>
                                        </p>
                                    </div>
                                </div>
                                <div className="win-number-result__item">
                                    <span>
                                        <div className="dot_pre"></div>
                                        3
                                    </span>
                                    <div>
                                        <p>
                                        <span>{generatedHash.hash_sub % 5}</span> is a win number of the Lucky Star corresponding to the table below
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="win-number-result__stars">
                                {starTable.map(star => (
                                    <div key={star.number} className={"win-number-result__staritem" + (star.winNumber.includes(generatedHash.hash_sub % 5) ? " _win" : '')}>
                                        <img src={starImg} alt="" />
                                        <div>{star.number}</div>
                                        <span>{star.winNumber.includes(generatedHash.hash_sub % 5) ? generatedHash.hash_sub % 5 : star.number !== 5 ? star.number : 0}</span>
                                    </div>
                                ))}
                            </div>
                            {/* <div className="win-number-result__sum">
                                <div>
                                    <span>Сумма</span>
                                    <span>{generatedHash.hash_sub}</span>
                                </div>
                                <div>
                                    <span>Получает</span>
                                    <span>{Math.floor(generatedHash.hash_sub / 5)}x{generatedHash.hash_sub % 5}</span>
                                </div>
                            </div>
                            <div className="table-win-number">
                                <span>ТАБЛИЦА</span>
                                {starTable.map(starRow => (
                                    <div 
                                        key={starRow.number}
                                        className={
                                            "table-win-number__row" + 
                                            (generatedHash.hash_sub % 5 === starRow.number ? " _active" : "") +
                                            (generatedHash.hash_sub % 5 - 1 === starRow.number ? " _active_prev" : "")
                                        }
                                    >
                                        <span>{starRow.number} = </span>
                                        <span>{starRow.winNumber}</span>
                                    </div>
                                ))}
                            </div> */}
                        </div>
                    </BoxWrapper>
                </>
                }
            </div>
        </>
    )
};

export default CheckWinNumber
