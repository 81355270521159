import React from 'react';
import { useLocation } from 'react-router-dom';
import { useApp } from '../../hooks/useApp';
import { useStarGame } from '../../hooks/useStarGame';
import TitleAddStars from './Titles/TitleAddStars';
import TitleAffilate from './Titles/TitleAffilate';
import TitleAffilatePremium from './Titles/TitleAffilatePremium';
import TitleHistory from './Titles/TitleHistory';
import winNumber from '../../assets/img/icons/win_star.svg'
 
const StarTitle = ({

}) => {
    const location = useLocation()
    const { isLoaded,  isPremium} = useApp()
    const {
        resultNumber
    } = useStarGame()

    if (location.pathname.includes('/check-win-num')) {
        return 
    }

    return (
        <div className="star-topper-title">
            {location.pathname.includes('history') && <TitleHistory />}
            
            {location.pathname.includes('affilate') && !isPremium && <TitleAffilate />}
            {location.pathname.includes('affilate') && isPremium && <TitleAffilatePremium />}
            {location.pathname.includes('/add-stars') && <TitleAddStars />}
            {location.pathname === '/' && !isLoaded && <p>Airdrop: <span>Lucky Star Token (LST)</span></p>}
            {location.pathname === '/' && isLoaded && <p style={{fontWeight: "500"}}>Airdrop: <span>Lucky Star Token (LST)</span></p>}
        </div>
    );
};

export default StarTitle;