import React, { useEffect, useState } from "react"
import BoxWrapper from "../../components/Wrappers/BoxWrapper";
import HistoryList from "../../components/HistoryList/HistoryList";
import { useApp } from "../../hooks/useApp";
import { useLocation, useNavigate } from "react-router-dom";
import { useScroll } from "../../hooks/useScroll";
import { useTelegram } from "../../hooks/useTelegram";
import { useScrollPosition } from "../../hooks/useScrollPosition";
import StarTitle from "../../components/Star/StarTitle";
import Star from "../../components/Star/Star";

const History= ({

}) => {
    const {
        setIsWithDraw
    } = useApp()

    const { hideTgButton } = useTelegram()
    const { scrollTop, scrollTopSmooth } = useScroll()
    const location = useLocation()
    const navigate = useNavigate()
    const [isBtmBtnsVisible, setIsBtmBtnsVisible] = useState(false)

    useScrollPosition(({prevPos, currPos}) => {
        const isShow = -currPos.y > 156
        const innerHeight = document.querySelector('html').offsetHeight
        if (isShow) {
            setIsBtmBtnsVisible(true)
        } else {
            setIsBtmBtnsVisible(false)
        }
    }, [isBtmBtnsVisible])

    useEffect(() => {
        scrollTop()
    }, [location.pathname])
    
    useEffect(() => {
        hideTgButton()
        setIsWithDraw(false)
    }, [])

    return (
        <>
            <Star />    
            <StarTitle />
            <div className="history-page">
                <div className="history-list-title">Full History</div>
                <HistoryList />
                <div className={"btm-fixed-btns" + (isBtmBtnsVisible ? " _visible" : "")}>
                    <div className="return-btm-btn" onClick={() => scrollTopSmooth()}></div>
                    <div className="return-btm-btn return-btm-btn_return" onClick={() => navigate('/')}></div>
                </div>
            </div>
        </>
    )
};

export default History


