import React, { useEffect } from "react"
import BoxWrapper from "../Wrappers/BoxWrapper"
import RequestButton from "../UI/RequestButton/RequestButton";
import { useAdm_GetPremiumPartnersQuery } from "../../store/services/starsGame";

const PremiumPartners = ({
    
}) => {
    const {data, isLoading, isError, refetch} = useAdm_GetPremiumPartnersQuery()

    useEffect(() => {
        if (data) {
            refetch()
        }
    }, [])

    return (
        <BoxWrapper>
            <div className="admin-partner-list">
                {data?.map(partner => (
                    <div key={partner.id} className="admin-partner-list__item">
                        <p>Telegram ID: {partner.tg_id}</p>
                        <div className="admin-partner-list__btns">
                            <RequestButton>Set default pship</RequestButton>
                            <RequestButton className={'_red'}>Ban user</RequestButton>
                        </div>
                    </div>
                ))}
            </div>
            <RequestButton className={'_green'}>+ Add premium partner</RequestButton>
        </BoxWrapper>
    )
};

export default PremiumPartners;
