import React from 'react';
import { useDispatch } from 'react-redux';
import { useApp } from '../../../hooks/useApp';
import { setConnectionErrorVisibility } from '../../../store/slices/appSlice/appSlice';
import RequestButton from '../RequestButton/RequestButton';
import Modal from './Modal';

const ConnectionErrorModal = ({
}) => {
    const {
        isConnectionError
    } = useApp()
    const dispatch = useDispatch()
    const handleErrorVisibility = (isVisible) => {
        dispatch(setConnectionErrorVisibility(isVisible))
    }

    return (
        <Modal
            isActive={isConnectionError}
            setActive={handleErrorVisibility}
            className={'addstars-modal network-error-modal'}      
        >
            <p>Something is wrong with your network connection.</p>
            <p>Please fix it and try again.</p>
            <RequestButton
                onClick={() => handleErrorVisibility(false)}
                isloading={false}
            >
                Ok
            </RequestButton>
        </Modal>
    );
};

export default ConnectionErrorModal;