import React from "react"

const TitleAddStars= ({}) => {
    return (
        <div className='title-addstars'>
            <div className="intro-star-topper__subtitle">Add stars</div>
            {/* <div className="title-affilate__text">
                Lorem, ipsum dolor sit amet.
            </div> */}
        </div>
    )
};

export default TitleAddStars
