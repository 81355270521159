import React, { useEffect } from "react"
import BoxWrapper from "../Wrappers/BoxWrapper";
import AffilateItem from "../AffilateList/AffilateItems/AffilateItem";
import RequestButton from "../UI/RequestButton/RequestButton";
import { useTelegram } from "../../hooks/useTelegram";
import { useAdm_AcceptWithdrawMutation, useAdm_GetPendingWithdrawsQuery } from "../../store/services/starsGame";

const PendingWithdraws = ({
}) => {
    const tonRate = 0.00126917
    const {user: tgUser, sendAlert} = useTelegram()
    const {data, isLoading, isError, refetch: refetchWithdraws} = useAdm_GetPendingWithdrawsQuery()
    const [acceptWithdraw, {data: acceptWData, isLoading: isAcceptWLoading}] = useAdm_AcceptWithdrawMutation()

    useEffect(() => {
        if (data) {
            refetchWithdraws()
        }
    }, [])


    const handleAcceptBtnClick = async (withdraw_id, user) => {
        await acceptWithdraw({
            tg_id: tgUser,
            withdraw_id: withdraw_id
        })
        sendAlert(`Withdraw with ID:${withdraw_id} was accepted for Telegram User: ${user}`)
    }

    if (!data?.length) {
        return (
            <BoxWrapper className={'history-list list-affilate'}>
                <p className="history-list__empty">No pending withdraws</p>
            </BoxWrapper>
        )
    }

    return (
        <BoxWrapper className={'history-list list-affilate'}>
            {data.map((affilateItem, index) => (
                <div key={affilateItem.id} className="admin-manage-withdraws__item">
                    <div className="admin-manage-withdraws__title">
                        <p>tg_id:<span>{affilateItem.tg_id}</span>:w_id:<span>{affilateItem.id}</span></p>
                    </div>
                    <AffilateItem 
                        affilateItem={affilateItem}
                        tonRate={tonRate}
                        key={`${affilateItem.type}_${index}`}
                    />
                    <RequestButton
                        onClick={() => handleAcceptBtnClick(affilateItem.id, affilateItem.tg_id)}
                        isloading={isAcceptWLoading}
                    >Accept</RequestButton>
                </div>
            ))}
        </BoxWrapper>
    )
};

export default PendingWithdraws;
