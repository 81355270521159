import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import modalBalanceImg from '../../assets/img/icons/modals/low_balance.svg';
import modalTriesImg from '../../assets/img/icons/modals/no_tries.svg';
import FairGame from '../../components/FairGame/FairGame';
import Footer from '../../components/Footer/Footer';
import Game from '../../components/Game/Game';
import ConnectionErrorModal from '../../components/UI/Modal/ConnectionErrorModal';
import Modal from '../../components/UI/Modal/Modal';
import RequestButton from '../../components/UI/RequestButton/RequestButton';
import BoxWrapper from '../../components/Wrappers/BoxWrapper';
import { useApp } from '../../hooks/useApp';
import { useScroll } from '../../hooks/useScroll';
import { useTelegram } from '../../hooks/useTelegram';
import { useGetBonusQuery } from "../../store/services/starsGame";
import AddStars from '../AddStars/AddStars';
import AddStarsGamePrize from '../AddStars/AddStarsGamePrize';
import TriesGame from '../Tries/TriesGame';
import TriesGamePrize from '../Tries/TriesGamePrize';
import Star from '../../components/Star/Star';
import StarTitle from '../../components/Star/StarTitle';

const Home = ({

}) => {

    const {
        setIsWithDraw,
        isLoaded,
        mainBalance,
        gamesLeft
    } = useApp()
    const { isVisibleBonus } = useSelector(state => state.addStar)
    const [isModalActive, setIsModalActive] = useState(false)
    const [isGamesLeftModalActive, setIsGamesLeftModalActive] = useState(false)
    const { hideTgButton, user: tgUser, sendAlert } = useTelegram()
    const { scrollTop, scrollTopSmooth } = useScroll()
    const location = useLocation()
    const {data: bonusData, isLoading: isBonusDataLoading, refetch: refetchBonus} = useGetBonusQuery(tgUser)
    
    useEffect(() => {
        // scrollTop()
        console.log('homepage')
    }, [])
    
    useEffect(() => {
        hideTgButton()
        setIsWithDraw(false)
    }, [])
    
    useEffect(() => {
        if (mainBalance < 10) {
            setIsModalActive(true)
        } else {
            setIsModalActive(false)
        }
    }, [mainBalance])

    useEffect(() => {
        if (gamesLeft === 0) {
            setIsGamesLeftModalActive(true)
        } else {
            setIsGamesLeftModalActive(false)
        }
    }, [gamesLeft])

    useEffect(() => {
        if (bonusData && !isBonusDataLoading && isModalActive) {
            setIsModalActive(false)
        }
    }, [bonusData, isBonusDataLoading])

    const handleAddStarsClickModal = () => {
        refetchBonus()
    }


    return (
        <div className='homepage'>
            <Star />    
            <StarTitle />
            {isLoaded &&
                <div className="s5-app__content">
                    {<AddStarsGamePrize />}
                    <TriesGame />
                    <TriesGamePrize />
                    {(mainBalance < 10) && <AddStars />}
                    {(mainBalance >= 10 && gamesLeft !== 0) && <Game />}
                
                    <Modal 
                        isActive={isModalActive}
                        setActive={() => {}}
                        className={'addstars-modal'}
                    >
                        <img src={modalBalanceImg} alt="" />
                        <p>Your balance is low</p>
                        <RequestButton
                            onClick={handleAddStarsClickModal}
                            isloading={isBonusDataLoading}
                        >
                            Add stars
                        </RequestButton>
                    </Modal>
                    <Modal 
                        isActive={isGamesLeftModalActive}
                        setActive={() => {}}
                        className={'addstars-modal'}
                    >
                        <img src={modalTriesImg} alt="" />
                        <p>You have no more tries</p>
                        <RequestButton
                            onClick={() => setIsGamesLeftModalActive(false)}
                            isloading={false}
                        >
                            Add tries
                        </RequestButton>
                    </Modal>
                    <ConnectionErrorModal />
                    <FairGame />
                    <Footer />
                    {tgUser === 658318611 &&
                        <BoxWrapper linkPath={'/admin/manage'}>
                            <p style={{textAlign: 'center'}}>ADMIN PANEL</p>
                        </BoxWrapper>
                    }  
                </div>
            }
        </div>
    );
};

export default Home;